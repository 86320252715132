<template>
  <rs-row>
    <rs-col :md="12">
      <rs-card title="首页" icon="home"></rs-card>
    </rs-col>
  </rs-row>
</template>

<script>
export default {
  name: 'Home'
}
</script>
